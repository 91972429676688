import React from 'react';
import MainBanner from '../HomeDefault/MainBanner';
import About from '../HomeDefault/About';
import WhyUs from '../Common/WhyUs';
import Speakers from '../HomeDefault/Speakers';
import GoTop from '../Shared/GoTop';
import Footer from '../Common/Footer';
import EventSchedules from '../HomeDefault/EventSchedules';
import FunFact from '../Common/FunFact';
import Pricing from '../HomeDefault/Pricing';
import lax from 'lax.js';
import Partner from '../Common/Partner';
import LatestNews from '../HomeDefault/LatestNews';
import BuyTicket from '../Common/BuyTicket';
import Subscribe from '../Common/Subscribe';
import Preloader from "../../components/Shared/Preloader";
import firebase from 'firebase';
import { history } from "../../history";
import Navigation from '../Navigation/Navigation';
import Faq from './Faq';
import Sponsor from './Sponsor';

class HomeDefault extends React.Component {
    state={
        modal:false,
    }
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    componentDidMount(){
        if(firebase.auth().currentUser){

        }else{
            console.log("Not logged in1");
            // history.push("/signup")
        }
    }
    toggleModal = () => {
        this.setState((prevState) => ({
          modal: !prevState.modal,
        }));
      };
    render(){
        return (
            <React.Fragment>
                <Navigation modal={this.state.modal} toggleModal={this.toggleModal} />
                {/* Main Banner */}
                <MainBanner  modal={this.state.modal} toggleModal={this.toggleModal} />
                {/* About Area */}
                <About  modal={this.state.modal} toggleModal={this.toggleModal} />
                {/* Why Choose Us Area */}
                <WhyUs  modal={this.state.modal} toggleModal={this.toggleModal} />
                {/* Speakers Area */}
              
                {/* Schedule Area */}
                <EventSchedules  modal={this.state.modal} toggleModal={this.toggleModal}  />
                {/* <Speakers /> */}
                <Sponsor/>
                <Faq  modal={this.state.modal} toggleModal={this.toggleModal}  />
                <Footer  modal={this.state.modal} toggleModal={this.toggleModal}    />

                {/* Back Top top */}
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default HomeDefault;