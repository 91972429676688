import firebase from "firebase";
import React from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import logo from "../../assets/images/hplogo2.png";
import { Spinner, Row, Col } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import SweetAlert from "react-bootstrap-sweetalert";
import {isMobile} from 'react-device-detect';

// import "flatpickr/dist/themes/light.css";
import "flatpickr/dist/themes/airbnb.css";

import {
  Label,
  Button,
  FormGroup,
  Form,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
class Navigation extends React.Component {
  state = {
    modal2: false,
    successAlert: false,
    collapsed: true,
    isOpen: false,
    modal: false,
    isloading: false,
    form: false,
    errorAlert: false,
    owndate: null,
    depdate: null,
    owncity: null,
    depcity: null,
    seatpref: null,
    mealpref: null,
    roomtype: null,
    accommeal: null,
    foodalergy: "",
    otherremarks: "",
    golf: null,
    trekslot: null,
    tshirt: null,
    errormsg: "",
    category: null,
    photo: null,
    photo2: null,
    photo3: null,
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
        window.history.pushState("", document.title, window.location.pathname);
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  componentDidUpdate(nextProps) {
    if (this.props.match.path !== nextProps.match.path) {
      // this.onRouteChanged();
      console.log("OK");
    }
  }
  // toggleModal = () => {
  //   this.setState((prevState) => ({
  //     modal: !prevState.modal,
  //   }));
  // };
  toggleModal2 = () => {
    this.setState((prevState) => ({
      modal2: !prevState.modal2,
    }));
  };
  onRouteChanged = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleRegister = (e) => {
    e.preventDefault();
    console.log(this.state);

    if (!this.state.photo) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Passport Front Page Photo.",
      });
    } else if (!this.state.photo2) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Passport Back Page Photo.",
      });
    }else if (this.state.clist === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Country Code",
      });
    } else if (!this.state.photo3) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select your Photograph.",
      });
    }
    // else if (!this.state.photo4) {
    //   this.setState({
    //     errorAlert: true,
    //     errormsg: "Please upload Vaccine Certificate.",
    //   });
    // }
     else if (this.state.accommeal === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Accomodation Meal Type.",
      });
    } else if (this.state.salutation === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Salutation",
      });
    }  else if (this.state.tshirt === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Collar Size.",
      });
    } else {
      console.log("Allow register");
      this.setState({
        infoloading: true,
      });

      var fileext = this.state.filetype.includes("png") ? ".png" : ".jpg";
      firebase
        .storage()
        .ref("files/" + new Date().getTime() + fileext)
        .putString(
          this.state.photo.replace(/^data:image\/[a-z]+;base64,/, ""),
          "base64",
          { contentType: this.state.filetype }
        )
        // .put(this.state.photo)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at1", downloadURL);

            var fileext2 = this.state.filetype2.includes("png")
              ? ".png"
              : ".jpg";
            firebase
              .storage()
              .ref("files/" + new Date().getTime() + fileext2)
              .putString(
                this.state.photo2.replace(/^data:image\/[a-z]+;base64,/, ""),
                "base64",
                { contentType: this.state.filetype2 }
              )
              // .put(this.state.photo)
              .then((snapshot2) => {
                snapshot2.ref.getDownloadURL().then((downloadURL2) => {
                  console.log("File available at2", downloadURL2);

                  var fileext3 = this.state.filetype3.includes("png")
                    ? ".png"
                    : ".jpg";
                  firebase
                    .storage()
                    .ref("files/" + new Date().getTime() + fileext3)
                    .putString(
                      this.state.photo3.replace(
                        /^data:image\/[a-z]+;base64,/,
                        ""
                      ),
                      "base64",
                      { contentType: this.state.filetype3 }
                    )
                    // .put(this.state.photo)
                    .then((snapshot3) => {
                      snapshot3.ref.getDownloadURL().then((downloadURL3) => {
                        console.log("File available at3", downloadURL3);
                        // var fileext4 =  ".pdf";
                        // firebase
                        //   .storage()
                        //   .ref("files/" + new Date().getTime() + fileext4)
                        //   .put(this.state.photo4)
                         
                        //   .then((snapshot4) => {
                        //     snapshot4.ref
                        //       .getDownloadURL()
                        //       .then((downloadURL4) => {
                        //         console.log("File available at4", downloadURL4);
                                var registeruser = firebase
                                  .app()
                                  .functions("asia-east2")
                                  .httpsCallable("createuser");
                                registeruser({
                                  firstname: this.state.firstname,
                                  middlename:this.state.middlename,
                                  lastname:this.state.lastname,
                                  countrycode:this.state.clist.value,
                                  state:this.state.state,
                                  pincode:this.state.pincode,
                                  emergencyname:this.state.emergencyname,
                                  emergencynumber:this.state.emergencynumber,

                                  emergencyrelation:this.state.emergencyrelation,

                                  city: this.state.city,

                                  email: this.state.email,
                                  number: this.state.number,
                                  organization: this.state.organization,
                                  designation: this.state.designation,
                                  salutation:this.state.salutation.label,
                                  accommeal: this.state.accommeal.label,
                                  foodalergy: this.state.foodalergy,
                                  tshirt: this.state.tshirt.label,
                                  // uaevisa:this.state.awsshare,
                                  passportfront: downloadURL,
                                  passportback: downloadURL2,
                                  photograph: downloadURL3,
                                  // vaccincecertificate:downloadURL4,
                                  time: new Date().toString(),
                                }).then((data) => {
                                  console.log(data);
                                  console.log(data.data.err);
                                  console.log(JSON.stringify(data));
                                  if (data.data.err == 0) {
                                    console.log("REgister done");
                                    this.setState({
                                      successAlertmsg:
                                        "Dear Partner, Thank you for registering for Ignite Partner Success 2023! We appreciate your interest in the event. Our event secretariat will reach out to you soon to discuss travel and logistics arrangements.",
                                      successAlert: true,
                                      isloading: false,
                                      firstname: "",
                                      middlename:"",
                                      lastname:"",
                                      name: "",
                                      city: "",
                                      clist:null,
                                      countrycode:"",
                                      state:"",
                                      pincode:"",
                                      salutation:null,
                                      email: "",
                                      number: "",
                                      organization: "",
                                      designation: "",
                                      accommeal: null,
                                      foodalergy: "",
                                      infoloading: false,
                                      modal: false,
                                      photo: null,
                                      photo2: null,
                                      photo3: null,
                                      photo4:null,
                                      tshirt: null,
                                      awsshare: false,
                                    });
                                    // history.push("/pages/main");
                                  } else if (data.data.err === 1) {
                                    console.log("Already registered");
                                    this.setState({
                                      errorAlert: true,
                                      errormsg: "You have already registered",
                                      infoloading: false,
                                    });
                                  } else if (data.data.err === 3) {
                                    console.log("Already registered");
                                    this.setState({
                                      errorAlert: true,
                                      errormsg: "Please check your email",
                                      infoloading: false,
                                    });
                                  } else {
                                    console.log("There was an error");
                                    this.setState({
                                      errorAlert: true,
                                      errormsg: "There was an error",
                                      infoloading: false,
                                    });
                                  }
                                });
                              })
                          })
                    //   });
                    // });
                })
              })
          })
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };
  render() {
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
    return (
      <header id="header" className="header-area">
        <SweetAlert
          error
          title="Error"
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <SweetAlert
          success
          title="Success"
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            Thank you for submitting the details, team will reach out to you for
            further information and bookings.
          </p>
        </SweetAlert>
        <Modal isOpen={this.state.modal2} toggle={this.toggleModal2}>
          <ModalHeader toggle={this.toggleModal2}>Size Chart</ModalHeader>
          <ModalBody>
            <img src="/tshirt.jpg" width={"100%"} />
          </ModalBody>
        </Modal>
        <Modal
          className="modal-dialog-centered modal-xl"
          backdrop="static"
          isOpen={this.props.modal}
          toggle={this.props.toggleModal}
        >
          <ModalHeader toggle={this.props.toggleModal}>HPE IGNITE PARTNER SUCCESS 2024</ModalHeader>
          <ModalBody>
            {true ? (
              <div style={{textAlign:"center",fontSize:20}}>Registrations are now closed.<br/>
              {/* For any further queries, please write to <a href="mailto:igniteaps2023@echoo.in">igniteaps2023@echoo.in</a> */}
              </div>
            ) : (
              <>
                <Form onSubmit={this.handleRegister}>
                  <Row>
                    <h5>Personal Information</h5>
                    <p>Please fill your personal information for the event </p>
                    <p>*All fields are mandatory</p>
                    <Col sm="3" style={{marginTop:5}}>
                    <FormGroup className="form-label-group">
                        <Select
                          required
                          className="React"
                          classNamePrefix="select"
                          value={this.state.salutation}
                          onChange={(a) => {
                            this.setState({
                              salutation: a,
                            });
                          }}
                          placeholder="Salutation"
                          options={[
                            {
                              value: "Mr",
                              label: "Mr",
                            },
                            {
                              value: "Ms",
                              label: "Ms",
                            },
                            {
                              value: "Mrs",
                              label: "Mrs",
                            }
                          ]}
                        />
                        {/* <Label>Company size </Label> */}
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="First Name"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.firstname}
                          onChange={(e) =>
                            this.setState({ firstname: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          
                          fontSize={30}
                          placeholder="Middle Name"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.middlename}
                          onChange={(e) =>
                            this.setState({ middlename: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="Last Name "
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.lastname}
                          onChange={(e) =>
                            this.setState({ lastname: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          type="email"
                          required
                          fontSize={30}
                          placeholder="Business Email ID"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          // type="number"
                          required
                          fontSize={30}
                          placeholder="Contact Number"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.number}
                          onChange={(e) =>
                            this.setState({ number: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
              {/* <Label>Country Code*</Label> */}
              <Select
                required
                placeholder={"Country Code"}
                value={this.state.clist}
                onChange={(a) => {
                  this.setState({
                    clist: a,
                  });
                }}
                options={[
                  { value: "+7 840", label: "Abkhazia (+7 840)" },
                  { value: "+93", label: "Afghanistan (+93)" },
                  { value: "+355", label: "Albania (+355)" },
                  { value: "+213", label: "Algeria (+213)" },
                  { value: "+1 684", label: "American Samoa (+1 684)" },
                  { value: "+376", label: "Andorra (+376)" },
                  { value: "+244", label: "Angola (+244)" },
                  { value: "+1 264", label: "Anguilla (+1 264)" },
                  { value: "+1 268", label: "Antigua and Barbuda (+1 268)" },
                  { value: "+54", label: "Argentina (+54)" },
                  { value: "+374", label: "Armenia (+374)" },
                  { value: "+297", label: "Aruba (+297)" },
                  { value: "+247", label: "Ascension (+247)" },
                  { value: "+61", label: "Australia (+61)" },
                  {
                    value: "+672",
                    label: "Australian External Territories (+672)",
                  },
                  { value: "+43", label: "Austria (+43)" },
                  { value: "+994", label: "Azerbaijan (+994)" },
                  { value: "+1 242", label: "Bahamas (+1 242)" },
                  { value: "+973", label: "Bahrain (+973)" },
                  { value: "+880", label: "Bangladesh (+880)" },
                  { value: "+1 246", label: "Barbados (+1 246)" },
                  { value: "+1 268", label: "Barbuda (+1 268)" },
                  { value: "+375", label: "Belarus (+375)" },
                  { value: "+32", label: "Belgium (+32)" },
                  { value: "+501", label: "Belize (+501)" },
                  { value: "+229", label: "Benin (+229)" },
                  { value: "+1 441", label: "Bermuda (+1 441)" },
                  { value: "+975", label: "Bhutan (+975)" },
                  { value: "+591", label: "Bolivia (+591)" },
                  { value: "+387", label: "Bosnia and Herzegovina (+387)" },
                  { value: "+267", label: "Botswana (+267)" },
                  { value: "+55", label: "Brazil (+55)" },
                  {
                    value: "+246",
                    label: "British Indian Ocean Territory (+246)",
                  },
                  { value: "+1 284", label: "British Virgin Islands (+1 284)" },
                  { value: "+673", label: "Brunei (+673)" },
                  { value: "+359", label: "Bulgaria (+359)" },
                  { value: "+226", label: "Burkina Faso (+226)" },
                  { value: "+257", label: "Burundi (+257)" },
                  { value: "+855", label: "Cambodia (+855)" },
                  { value: "+237", label: "Cameroon (+237)" },
                  { value: "+1", label: "Canada (+1)" },
                  { value: "+238", label: "Cape Verde (+238)" },
                  { value: "+ 345", label: "Cayman Islands (+ 345)" },
                  { value: "+236", label: "Central African Republic (+236)" },
                  { value: "+235", label: "Chad (+235)" },
                  { value: "+56", label: "Chile (+56)" },
                  { value: "+86", label: "China (+86)" },
                  { value: "+61", label: "Christmas Island (+61)" },
                  { value: "+61", label: "Cocos-Keeling Islands (+61)" },
                  { value: "+57", label: "Colombia (+57)" },
                  { value: "+269", label: "Comoros (+269)" },
                  { value: "+242", label: "Congo (+242)" },
                  {
                    value: "+243",
                    label: "Congo, Dem. Rep. of (Zaire) (+243)",
                  },
                  { value: "+682", label: "Cook Islands (+682)" },
                  { value: "+506", label: "Costa Rica (+506)" },
                  { value: "+385", label: "Croatia (+385)" },
                  { value: "+53", label: "Cuba (+53)" },
                  { value: "+599", label: "Curacao (+599)" },
                  { value: "+537", label: "Cyprus (+537)" },
                  { value: "+420", label: "Czech Republic (+420)" },
                  { value: "+45", label: "Denmark (+45)" },
                  { value: "+246", label: "Diego Garcia (+246)" },
                  { value: "+253", label: "Djibouti (+253)" },
                  { value: "+1 767", label: "Dominica (+1 767)" },
                  { value: "+1 809", label: "Dominican Republic (+1 809)" },
                  { value: "+670", label: "East Timor (+670)" },
                  { value: "+56", label: "Easter Island (+56)" },
                  { value: "+593", label: "Ecuador (+593)" },
                  { value: "+20", label: "Egypt (+20)" },
                  { value: "+503", label: "El Salvador (+503)" },
                  { value: "+240", label: "Equatorial Guinea (+240)" },
                  { value: "+291", label: "Eritrea (+291)" },
                  { value: "+372", label: "Estonia (+372)" },
                  { value: "+251", label: "Ethiopia (+251)" },
                  { value: "+500", label: "Falkland Islands (+500)" },
                  { value: "+298", label: "Faroe Islands (+298)" },
                  { value: "+679", label: "Fiji (+679)" },
                  { value: "+358", label: "Finland (+358)" },
                  { value: "+33", label: "France (+33)" },
                  { value: "+596", label: "French Antilles (+596)" },
                  { value: "+594", label: "French Guiana (+594)" },
                  { value: "+689", label: "French Polynesia (+689)" },
                  { value: "+241", label: "Gabon (+241)" },
                  { value: "+220", label: "Gambia (+220)" },
                  { value: "+995", label: "Georgia (+995)" },
                  { value: "+49", label: "Germany (+49)" },
                  { value: "+233", label: "Ghana (+233)" },
                  { value: "+350", label: "Gibraltar (+350)" },
                  { value: "+30", label: "Greece (+30)" },
                  { value: "+299", label: "Greenland (+299)" },
                  { value: "+1 473", label: "Grenada (+1 473)" },
                  { value: "+590", label: "Guadeloupe (+590)" },
                  { value: "+1 671", label: "Guam (+1 671)" },
                  { value: "+502", label: "Guatemala (+502)" },
                  { value: "+224", label: "Guinea (+224)" },
                  { value: "+245", label: "Guinea-Bissau (+245)" },
                  { value: "+595", label: "Guyana (+595)" },
                  { value: "+509", label: "Haiti (+509)" },
                  { value: "+504", label: "Honduras (+504)" },
                  { value: "+852", label: "Hong Kong SAR China (+852)" },
                  { value: "+36", label: "Hungary (+36)" },
                  { value: "+354", label: "Iceland (+354)" },
                  { value: "+91", label: "India (+91)" },
                  { value: "+62", label: "Indonesia (+62)" },
                  { value: "+98", label: "Iran (+98)" },
                  { value: "+964", label: "Iraq (+964)" },
                  { value: "+353", label: "Ireland (+353)" },
                  { value: "+972", label: "Israel (+972)" },
                  { value: "+39", label: "Italy (+39)" },
                  { value: "+225", label: "Ivory Coast (+225)" },
                  { value: "+1 876", label: "Jamaica (+1 876)" },
                  { value: "+81", label: "Japan (+81)" },
                  { value: "+962", label: "Jordan (+962)" },
                  { value: "+7 7", label: "Kazakhstan (+7 7)" },
                  { value: "+254", label: "Kenya (+254)" },
                  { value: "+686", label: "Kiribati (+686)" },
                  { value: "+965", label: "Kuwait (+965)" },
                  { value: "+996", label: "Kyrgyzstan (+996)" },
                  { value: "+856", label: "Laos (+856)" },
                  { value: "+371", label: "Latvia (+371)" },
                  { value: "+961", label: "Lebanon (+961)" },
                  { value: "+266", label: "Lesotho (+266)" },
                  { value: "+231", label: "Liberia (+231)" },
                  { value: "+218", label: "Libya (+218)" },
                  { value: "+423", label: "Liechtenstein (+423)" },
                  { value: "+370", label: "Lithuania (+370)" },
                  { value: "+352", label: "Luxembourg (+352)" },
                  { value: "+853", label: "Macau SAR China (+853)" },
                  { value: "+389", label: "Macedonia (+389)" },
                  { value: "+261", label: "Madagascar (+261)" },
                  { value: "+265", label: "Malawi (+265)" },
                  { value: "+60", label: "Malaysia (+60)" },
                  { value: "+960", label: "Maldives (+960)" },
                  { value: "+223", label: "Mali (+223)" },
                  { value: "+356", label: "Malta (+356)" },
                  { value: "+692", label: "Marshall Islands (+692)" },
                  { value: "+596", label: "Martinique (+596)" },
                  { value: "+222", label: "Mauritania (+222)" },
                  { value: "+230", label: "Mauritius (+230)" },
                  { value: "+262", label: "Mayotte (+262)" },
                  { value: "+52", label: "Mexico (+52)" },
                  { value: "+691", label: "Micronesia (+691)" },
                  { value: "+1 808", label: "Midway Island (+1 808)" },
                  { value: "+373", label: "Moldova (+373)" },
                  { value: "+377", label: "Monaco (+377)" },
                  { value: "+976", label: "Mongolia (+976)" },
                  { value: "+382", label: "Montenegro (+382)" },
                  { value: "+1664", label: "Montserrat (+1664)" },
                  { value: "+212", label: "Morocco (+212)" },
                  { value: "+95", label: "Myanmar (+95)" },
                  { value: "+264", label: "Namibia (+264)" },
                  { value: "+674", label: "Nauru (+674)" },
                  { value: "+977", label: "Nepal (+977)" },
                  { value: "+31", label: "Netherlands (+31)" },
                  { value: "+599", label: "Netherlands Antilles (+599)" },
                  { value: "+1 869", label: "Nevis (+1 869)" },
                  { value: "+687", label: "New Caledonia (+687)" },
                  { value: "+64", label: "New Zealand (+64)" },
                  { value: "+505", label: "Nicaragua (+505)" },
                  { value: "+227", label: "Niger (+227)" },
                  { value: "+234", label: "Nigeria (+234)" },
                  { value: "+683", label: "Niue (+683)" },
                  { value: "+672", label: "Norfolk Island (+672)" },
                  { value: "+850", label: "North Korea (+850)" },
                  {
                    value: "+1 670",
                    label: "Northern Mariana Islands (+1 670)",
                  },
                  { value: "+47", label: "Norway (+47)" },
                  { value: "+968", label: "Oman (+968)" },
                  { value: "+92", label: "Pakistan (+92)" },
                  { value: "+680", label: "Palau (+680)" },
                  { value: "+970", label: "Palestinian Territory (+970)" },
                  { value: "+507", label: "Panama (+507)" },
                  { value: "+675", label: "Papua New Guinea (+675)" },
                  { value: "+595", label: "Paraguay (+595)" },
                  { value: "+51", label: "Peru (+51)" },
                  { value: "+63", label: "Philippines (+63)" },
                  { value: "+48", label: "Poland (+48)" },
                  { value: "+351", label: "Portugal (+351)" },
                  { value: "+1 787", label: "Puerto Rico (+1 787)" },
                  { value: "+974", label: "Qatar (+974)" },
                  { value: "+262", label: "Reunion (+262)" },
                  { value: "+40", label: "Romania (+40)" },
                  { value: "+7", label: "Russia (+7)" },
                  { value: "+250", label: "Rwanda (+250)" },
                  { value: "+685", label: "Samoa (+685)" },
                  { value: "+378", label: "San Marino (+378)" },
                  { value: "+966", label: "Saudi Arabia (+966)" },
                  { value: "+221", label: "Senegal (+221)" },
                  { value: "+381", label: "Serbia (+381)" },
                  { value: "+248", label: "Seychelles (+248)" },
                  { value: "+232", label: "Sierra Leone (+232)" },
                  { value: "+65", label: "Singapore (+65)" },
                  { value: "+421", label: "Slovakia (+421)" },
                  { value: "+386", label: "Slovenia (+386)" },
                  { value: "+252", label: "Somalia (+252)" },
                  { value: "+677", label: "Solomon Islands (+677)" },
                  { value: "+27", label: "South Africa (+27)" },
                  {
                    value: "+500",
                    label:
                      "South Georgia and the South Sandwich Islands (+500)",
                  },
                  { value: "+82", label: "South Korea (+82)" },
                  { value: "+34", label: "Spain (+34)" },
                  { value: "+94", label: "Sri Lanka (+94)" },
                  { value: "+249", label: "Sudan (+249)" },
                  { value: "+597", label: "Suriname (+597)" },
                  { value: "+268", label: "Swaziland (+268)" },
                  { value: "+46", label: "Sweden (+46)" },
                  { value: "+41", label: "Switzerland (+41)" },
                  { value: "+963", label: "Syria (+963)" },
                  { value: "+886", label: "Taiwan (+886)" },
                  { value: "+992", label: "Tajikistan (+992)" },
                  { value: "+255", label: "Tanzania (+255)" },
                  { value: "+66", label: "Thailand (+66)" },
                  { value: "+670", label: "Timor Leste (+670)" },
                  { value: "+228", label: "Togo (+228)" },
                  { value: "+690", label: "Tokelau (+690)" },
                  { value: "+676", label: "Tonga (+676)" },
                  { value: "+1 868", label: "Trinidad and Tobago (+1 868)" },
                  { value: "+216", label: "Tunisia (+216)" },
                  { value: "+90", label: "Turkey (+90)" },
                  { value: "+993", label: "Turkmenistan (+993)" },
                  {
                    value: "+1 649",
                    label: "Turks and Caicos Islands (+1 649)",
                  },
                  { value: "+688", label: "Tuvalu (+688)" },
                  { value: "+1 340", label: "U.S. Virgin Islands (+1 340)" },
                  { value: "+256", label: "Uganda (+256)" },
                  { value: "+380", label: "Ukraine (+380)" },
                  { value: "+971", label: "United Arab Emirates (+971)" },
                  { value: "+44", label: "United Kingdom (+44)" },
                  { value: "+1", label: "United States (+1)" },
                  { value: "+598", label: "Uruguay (+598)" },
                  { value: "+998", label: "Uzbekistan (+998)" },
                  { value: "+678", label: "Vanuatu (+678)" },
                  { value: "+58", label: "Venezuela (+58)" },
                  { value: "+84", label: "Vietnam (+84)" },
                  { value: "+1 808", label: "Wake Island (+1 808)" },
                  { value: "+681", label: "Wallis and Futuna (+681)" },
                  { value: "+967", label: "Yemen (+967)" },
                  { value: "+260", label: "Zambia (+260)" },
                  { value: "+255", label: "Zanzibar (+255)" },
                  { value: "+263", label: "Zimbabwe (+263)" },
                ]}
              />
            </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="City"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.city}
                          onChange={(e) =>
                            this.setState({ city: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="State"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.state}
                          onChange={(e) =>
                            this.setState({ state: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="PinCode"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.pincode}
                          onChange={(e) =>
                            this.setState({ pincode: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="Designation"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.designation}
                          onChange={(e) =>
                            this.setState({ designation: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="Organization"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.organization}
                          onChange={(e) =>
                            this.setState({ organization: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/* <h5>Accommodation</h5> */}
                    {/* <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.roomtype}
                        onChange={(a) => {
                          this.setState({
                            roomtype: a,
                          });
                        }}
                        placeholder="Room Type"
                        options={[
                          {
                            value: "Non smoking",
                            label: "Non smoking",
                          },
                          {
                            value: "Smoking",
                            label: "Smoking",
                          },
                        ]}
                      />
                    </FormGroup>
                        <p>*subject to availability</p> */}
                    
                    {/* <FormGroup className="form-label-group">
                      <Input
                        type="text"
                        placeholder="Other Remarks "
                        value={this.state.otherremarks}
                        onChange={(e) =>
                          this.setState({ otherremarks: e.target.value })
                        }
                      />
                    </FormGroup> */}
                    {/* <br /> */}
                    {/* <h5>Activities</h5>
                    <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.golf}
                        onChange={(a) => {
                          this.setState({
                            golf: a,
                          });
                        }}
                        placeholder="Golf"
                        options={[
                          {
                            value: "Certified Golfer",
                            label: "Certified Golfer",
                          },
                          {
                            value: "Participate in Golf Clinic ",
                            label: "Participate in Golf Clinic ",
                          },
                        ]}
                      />
                    </FormGroup>
                    <h5>* Sunrise Trek in Nandi Hills - 06:30 -07:30hrs</h5> */}
                    {/* <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.trekslot}
                        onChange={(a) => {
                          this.setState({
                            trekslot: a,
                          });
                        }}
                        placeholder="Trekking"
                        options={[
                          {
                            value: "Sunrise Trek – 05:30 – 07:30 ",
                            label: "Sunrise Trek – 05:30 – 07:30 ",
                          },
                          {
                            value: "Normal Trek – 06:30 – 08:30 ",
                            label: "Normal Trek – 06:30 – 08:30 ",
                          },
                        ]}
                      />
                    </FormGroup> */}

                    {/* <br /> */}
                   
                    {/* <FormGroup check>
                      <Label check>
                        <Input
                          
                          type="checkbox"
                          onChange={(e) => {
                            console.log(e.target.checked);
                            this.setState({
                              awsshare: !this.state.awsshare,
                            });
                          }}
                        />{" "}
                        <div style={{ color: "#000", fontSize: 14 }}>
                          Do you have a valid UAE Visa
                         
                          <br /> <br />
                        </div>
                      </Label>
                    </FormGroup> */}
                    <h5>EMERGENCY CONTACT INFORMATION</h5>
                    <p>Please share your emergency contact information </p>
                    <Col sm="4">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="Full Name"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.emergencyname}
                          onChange={(e) =>
                            this.setState({ emergencyname: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="Contact Number"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.emergencynumber}
                          onChange={(e) =>
                            this.setState({ emergencynumber: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          required
                          fontSize={30}
                          placeholder="Relation"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.emergencyrelation}
                          onChange={(e) =>
                            this.setState({ emergencyrelation: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <hr/>
                    <h5>VISA DOCUMENTS</h5>
<p>Please upload the below requested documents as per the requirements in order to apply for visa for the conference in Phuket </p>
                    
                    {/* <h4>Visa Segment</h4> */}
                    <h5>Upload your Passport Scan Copy (only JPG or PNG less than 2 Mb) </h5>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        {this.state.photo ? (
                          <div style={{ padding: 10 }}>
                            <img width={"100%"} src={this.state.photo} />
                          </div>
                        ) : null}
                        <div style={{ display: "flex" }}>
                          {/* <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#000",
                              fontSize: 10,
                            }}
                          >
                            Front Page
                          </div>
                         */}
                          <input
                            id="myInput"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            ref={(ref) => (this.upload = ref)}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              var file = event.target.files[0];
                              console.log(file);
                              if(file.size>2097152){
alert("Please select file size less than 2mb")
                              }else{
                                console.log(file.type);
                                if(!(file.type.includes("png")||file.type.includes("jpg")||file.type.includes("jpeg"))){
                                  alert("Please select only JPG or PNG file")
                                }
                              else{
                              var imagebase64 = "";
                              var reader = new FileReader();
                              reader.onloadend = () => {
                                imagebase64 = reader.result;
                                this.setState({
                                  photo: imagebase64,
                                  filetype: file.type,
                                });
                              };
                              reader.readAsDataURL(file);
                            }
                          }
                              // this.setState({file});
                            }}
                          />
                          <div
                            className="registerbutton2"
                            disabled={this.state.isloading}
                            color="warning"
                            style={{ margin: 10 }}
                            type="submit"
                            onClick={() => {
                              this.upload.click();
                            }}
                          >
                            Front Page (Browse Photo)
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        {this.state.photo2 ? (
                          <div style={{ padding: 10 }}>
                            <img width={"100%"} src={this.state.photo2} />
                          </div>
                        ) : null}
                        <div style={{ display: "flex" }}>
                          {/* <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#000",
                              fontSize: 10,
                            }}
                          >
                            Back Page
                          </div> */}

                          <input
                            id="myInput"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            ref={(ref) => (this.upload2 = ref)}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              var file = event.target.files[0];
                              console.log(file);
                              if(file.size>2097152){
                                alert("Please select file size less than 2mb")
                                                              }else{
                                                                if(!(file.type.includes("png")||file.type.includes("jpg")||file.type.includes("jpeg"))){
                                                                  alert("Please select only JPG or PNG file")
                                                                }
                                                              else{
                              var imagebase64 = "";
                              var reader = new FileReader();
                              reader.onloadend = () => {
                                imagebase64 = reader.result;
                                this.setState({
                                  photo2: imagebase64,
                                  filetype2: file.type,
                                });
                              };
                            }
                              reader.readAsDataURL(file);
                            }
                              // this.setState({file});
                            }}
                          />
                          <div
                            className="registerbutton3"
                            disabled={this.state.isloading}
                            color="warning"
                            style={{ margin: 10 }}
                            type="submit"
                            onClick={() => {
                              this.upload2.click();
                            }}
                          >
                            Back Page(Browse Photo)
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5>Upload your photograph  (only JPG or PNG less than 2 Mb) </h5>
                    <p>
                      *White Background ; 35x45mm ; 80% face exposure and avoid eyewear*
                    </p>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        {this.state.photo3 ? (
                          <div style={{ padding: 10 }}>
                            <img width={"100%"} src={this.state.photo3} />
                          </div>
                        ) : null}
                        <div style={{ display: "flex" }}>
                          {/* <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#000",
                              fontSize: 10,
                            }}
                          >
                            Front Page
                          </div>
                         */}
                          <input
                            id="myInput"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            ref={(ref) => (this.upload3 = ref)}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              var file = event.target.files[0];
                              console.log(file);
                              if(file.size>2097152){
                                alert("Please select file size less than 2mb")
                                                              }else{
                                                                if(!(file.type.includes("png")||file.type.includes("jpg")||file.type.includes("jpeg"))){
                                                                  alert("Please select only JPG or PNG file")
                                                                }
                                                              else{
                              var imagebase64 = "";
                              var reader = new FileReader();
                              reader.onloadend = () => {
                                imagebase64 = reader.result;
                                this.setState({
                                  photo3: imagebase64,
                                  filetype3: file.type,
                                });
                              };
                            }
                              reader.readAsDataURL(file);
                            }
                              // this.setState({file});
                            }}
                          />
                          <div
                            className="registerbutton2"
                            disabled={this.state.isloading}
                            color="warning"
                            style={{ margin: 10 }}
                            type="submit"
                            onClick={() => {
                              this.upload3.click();
                            }}
                          >
                            Browse Photo
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <p>The HPE Ignite 2023 team continues to monitor guidelines for health and safety at the event. </p>
                    <h5>Kindly upload your proof of vaccination  (only PDF less than 2 Mb) </h5>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        {this.state.photo4 ? (
                          <div style={{ padding: 10 }}>
                            <img
                              width={"100%"}
                              src={this.state.photo4}
                              alt="Certificate"
                            />
                          </div>
                        ) : null}
                        <div style={{ display: "flex" }}>
                         
                          <input
                            id="myInput"
                            type="file"
                            accept="application/pdf"
                            ref={(ref) => (this.upload4 = ref)}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              var file = event.target.files[0];
                              console.log(file);
                              if(file.size>2097152){
                                alert("Please select file size less than 2mb")
                                                              }else{
                              var imagebase64 = "";
                              var reader = new FileReader();
                              reader.onloadend = () => {
                                imagebase64 = reader.result;
                                this.setState({
                                  photo4: file,
                                  filetype4: file.type,
                                });
                              };
                              reader.readAsDataURL(file);
                            }
                            }}
                          />
                          <div
                            className="registerbutton2"
                            disabled={this.state.isloading}
                            color="warning"
                            style={{ margin: 10 }}
                            type="submit"
                            onClick={() => {
                              this.upload4.click();
                            }}
                          >
                            Browse File
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <hr/>
                    <h5>OTHER REQUIRED DETAILS </h5>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h7>Collar Size - US, Regular Fit</h7>
                      {/* <h7
                        onClick={() => {
                          console.log("modal2 opening");
                          this.setState({
                            modal2: true,
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          color: "#00b388",
                          float: "right",
                        }}
                      >
                        Size Chart
                      </h7> */}
                    </div>
                    <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.tshirt}
                        onChange={(a) => {
                          this.setState({
                            tshirt: a,
                          });
                        }}
                        placeholder="Collar Size"
                        options={[
                          {
                            value: "XS - 36 ",
                            label: "XS - 36 ",
                          },
                          {
                            value: "S - 38",
                            label: "S - 38",
                          },
                          {
                            value: "M - 40",
                            label: "M - 40",
                          },
                          {
                            value: "L - 42",
                            label: "L - 42",
                          },
                          {
                            value: "XL - 44",
                            label: "XL - 44",
                          },
                          {
                            value: "XXL - 46",
                            label: "XXL - 46",
                          },
                          {
                            value: "3XL - 48",
                            label: "3XL - 48",
                          },
                        ]}
                      />
                      {/* <Label>Company size </Label> */}
                    </FormGroup>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Select
                          required
                          className="React"
                          classNamePrefix="select"
                          value={this.state.accommeal}
                          onChange={(a) => {
                            this.setState({
                              accommeal: a,
                            });
                          }}
                          placeholder="Meal preference "
                          options={[
                            {
                              value: "Vegetarian",
                              label: "Vegetarian",
                            },
                            {
                              value: "Non Vegetarian",
                              label: "Non Vegetarian",
                            },
                            {
                              value: "Jain",
                              label: "Jain",
                            },
                            {
                              value: "Vegan",
                              label: "Vegan",
                            },
                          ]}
                        />
                        {/* <Label>Company size </Label> */}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="form-label-group">
                        <Input
                          type="text"
                          fontSize={30}
                          placeholder="Food Allergies, if any"
                          // style={{fontSize: 30}}
                          // autoComplete="off"
                          value={this.state.foodalergy}
                          onChange={(e) =>
                            this.setState({ foodalergy: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  
                    <FormGroup check>
                      <Label check>
                        <Input
                          
                          type="checkbox"
                          onChange={(e) => {
                            console.log(e.target.checked);
                            this.setState({
                              awsshare2: !this.state.awsshare2,
                            });
                          }}
                        />{" "}
                      
                        <div style={{ color: "#000", fontSize: 14 }}>
                       For HPE partners only<br/>
                          {/* By Clicking on ‘I Agree’, you agree to the processing of your personal data by L’Oréal in accordance with its Privacy Policy and Terms and Conditions */}
                          By submitting my registration, I am likely to participate at HPE IGNITE PARTNER SUCCESS 2024 (the "Event"), and I will only accept gifts, meals, promotional items and entertainment (collectively "amenities") that are not prohibited by my organization's policies. I confirm that I (and not Hewlett Packard Enterprise) shall be responsible for managing compliance with my organization's policies.
I understand that attendance at this activity will include entertainment, gift and meals. HPE is providing you with this information to facilitate your compliance with any applicable regulations and/or your organizational policies.

                          <br />
                          <br />
                          {/* of this contest */}
                          Hewlett Packard Enterprise Company and its
                          subsidiaries (HPE or We) respect your privacy. This
                          Privacy Statement informs you about our privacy
                          practices including details of the personal data we
                          collect, use, disclose and transfer as well as choices
                          you can make and rights you can exercise in relation
                          to your personal data.
                          <br /> <br />
                        </div>
                      </Label>
                    </FormGroup>
                    {/* <div style={{ textAlign: "center" }}>
                      <img src="/tshirt.jpg" width={"40%"} />
                    </div> */}
                    <p style={{textAlign:"center"}}>Kindly note the submission may take a few seconds<br/>
Thank you for your patience
<br/>For any assistance, please email - harshita.nair@framez.sg or call 9448066560</p>
                    <div className={"d-flex justify-content-center"}>

                      <Button
                        className="registerbutton"
                        disabled={this.state.infoloading}
                        // color="warning"
                        type="submit"
                      >
                        {this.state.infoloading ? (
                          <Spinner color="red" />
                        ) : (
                          "Save Info"
                        )}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </>
            )}
          </ModalBody>
        </Modal>
        <div id="navbar" className="elkevent-nav">
          <nav className="navbar navbar-expand-md navbar-light">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="logo" width={120} />
              </Link>
              {isMobile?null:
           null}

              <button
                onClick={this.toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("about");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      About
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("whyus");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Why Attend
                    </a>
                  </li>
                  {/* <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("speakers");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Speakers
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("agenda");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Agenda
                    </a>
                  </li>
                  {/* <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("speaker");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Speakers
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("faq");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      FAQ
                    </a>
                  </li>
                  {/* <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("funfact");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      FunFact
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("sponsor");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Sponsor
                    </a>
                  </li>
                {/*   <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("faq");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      FAQ
                    </a>
                  </li>

                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("register");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Register
                    </a>
                  </li> */}

                  {/* <li className="nav-item">
                    <NavLink
                      to="/contact"
                      className="nav-link"
                      onClick={this.toggleNavbar}
                    >
                      Contact
                    </NavLink>
                  </li> */}
                </ul>

                <div className="others-option">
                  <ul>
                    <li>
                      <button
                        to="/pricing-1"
                        disabled={this.state.isloading}
                        className="btn btn-primary"
                        onClick={() => {
                          this.setState({
                            // isloading: true,
                            isloading: false,
                            // modal: true,
                            form: false,
                            // category: snap.val().category,
                          });
                          this.props.toggleModal()
                          // console.log(firebase.auth().currentUser.uid);
                          // firebase
                          //   .database()
                          //   .ref("pub_user/" + firebase.auth().currentUser.uid)
                          //   .once("value", (snap) => {
                          //     console.log(snap.val());
                          //     if (snap.val().form) {
                          //       this.setState({
                          //         isloading: false,
                          //         modal: true,
                          //         form: true,
                          //       });
                          //     } else {
                          //       console.log("Form not submitted");
                          //       this.setState({
                          //         isloading: false,
                          //         modal: true,
                          //         form: false,
                          //         category: snap.val().category,
                          //       });
                          //     }
                          //   })
                          //   .catch((err) => {
                          //     console.log(err);
                          //   });

                          // firebase.auth().signOut();
                        }}
                      >
                        {this.state.isloading ? (
                          <Spinner color="red" />
                        ) : (
                          "Registration"
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default withRouter(Navigation);
