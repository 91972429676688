import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
class WhyUs extends React.Component {
  render() {
    return (
      <>
        {/* <section className="about-area ptb-120 bg-image" id="whyus">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="about-content" style={{ fontSize: 18 }}>
                <p style={{ fontSize: 15 }}>
                  Making money and staying relevant and ahead of your
                  competitors is what it’s all about. Partnering with Hitachi
                  Vantara brings together the best of both worlds. Leading
                  technology, rich incentives for greater profitability, with
                  collaboration and teamwork to help you succeed.
                </p>
                <p style={{ fontSize: 15 }}>
                  At Hitachi Vantara, we give our partners the front-row access
                  to emerging technologies like cloud, object storage, Internet
                  of Things (IoT) and much more.
                </p>

                <p style={{ fontSize: 15 }}>
                  Our offerings help customers adapt to the needs of workloads
                  of the future with a modernized edge-to-core-to-cloud
                  infrastructure that delivers agility, efficiency, and
                  resiliency. Take an integrated, strategic approach to
                  application modernization, data modernization and
                  infrastructure modernization. Work from this digital
                  foundation to innovate, evolve and deliver exceptional
                  experiences for our customers.
                </p>

                <p style={{ fontSize: 15 }}>
                  As our valued partner, you will have the chance to expand your
                  value by leveraging the Hitachi Vantara offerings.
                </p>
              </div>
            </div>
          </div>
           
        </section> */}

        <section className="why-choose-us" id="whyus">
       
          <div className="row m-30 g-5" style={{ textAlign: "center",padding:30 }}>
          <h1 >Why Attend?</h1>
            <div
              className="col-lg-4 col-sm-6 p-3"
              style={{
             
              }}
            >
              <div className="card  shadow-sm" style={{padding:5,  backgroundColor: "#fff",  
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",height:250,}}>
                <div className="icon">
                  <i className="icofont-rocket iconsizecolor"></i>
                </div>

                <h3 style={{ textAlign: "center" ,marginTop:10}}> Innovate with HPE</h3>
                <p>
                Stay ahead in Edge, Hybrid Cloud, and AI with HPE's groundbreaking
solutions
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 p-3" >
              {/* <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell"> */}
                    <div className="card  shadow-sm" style={{padding:5,  backgroundColor: "#fff",  
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",height:250,}}>
                      <div className="icon">
                        <i className="icofont-chart-growth iconsizecolor"></i>
                      </div>

                      <h3 style={{ textAlign: "center" ,marginTop:10}}>
                        {" "}
                        Unlock Profitable Growth
                      </h3>
                      <p>
                      Discover margin-rich opportunities across HPE's diverse
portfolio and services
                      </p>
                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  {/* </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-4 col-sm-6 p-3" >
              {/* <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell"> */}
                    <div className="card  shadow-sm" style={{padding:5,  backgroundColor: "#fff",  
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",height:250,}}>
                      <div className="icon">
                        <i className="icofont-business-man iconsizecolor"></i>
                      </div>

                      <h3 style={{ textAlign: "center" ,marginTop:10}}>
                        {" "}
                        Lead with Partner Ready Vantage
                      </h3>
                      <p>
                      Leverage exclusive IP to boost growth with HPE
Partner Ready Vantage.
                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  {/* </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-4 col-sm-6 p-3"  >
              {/* <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell"> */}
                    <div className="card  shadow-sm" style={{ backgroundColor: "#fff",  
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",height:250,}}>
                      <div className="icon">
                        <i className="icofont-gear iconsizecolor"></i>
                      </div>

                      <h3 style={{ textAlign: "center" ,marginTop:10}}>
                      Streamline with HPE GreenLake
                      </h3>
                      <p>
                      Simplify quoting and drive repeatable business with
HPE GreenLake.
                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  </div>
                {/* </div>
              </div>
            </div> */}

            <div className="col-lg-4 col-sm-6 p-3" >
              {/* <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell"> */}
                    <div className="card  shadow-sm" style={{padding:5,  backgroundColor: "#fff",  
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",height:250,}}>
                      <div className="icon">
                        <i className="icofont-star iconsizecolor"></i>
                      </div>
                      <h3 style={{ textAlign: "center" ,marginTop:10}}>Deliver Superior Experiences</h3>
                      <p>
                      Drive transformative outcomes and enhance customer
relevance.
                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  </div>
                {/* </div>
              </div>
            </div> */}

         
            <div className="col-lg-4 col-sm-6 p-3"  >
              {/* <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell"> */}
                    <div className="card  shadow-sm" style={{padding:5,  backgroundColor: "#fff",  
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",height:250,}}>
                      <div className="icon">
                        <i className="icofont-link iconsizecolor"></i>
                      </div>
                      <h3 style={{ textAlign: "center" ,marginTop:10}}>Connect for Expansion</h3>
                      <p>
                      Engage with peers and leaders to seize new opportunities and
expand your network.
                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  </div>
                {/* </div>
              </div>
            </div> */}
          </div>

          {/* <ul className="slideshow">
            <li>
              <span></span>
            </li>
            <li>
              <span></span>
            </li>
            <li>
              <span></span>
            </li>
            <li>
              <span></span>
            </li>
          </ul> */}
        </section>
      </>
    );
  }
}

export default WhyUs;
