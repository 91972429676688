import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class EventSchedules extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render(){
        return (
            <section className="schedule-area bg-image ptb-120" id="agenda">
                <div className="container">
                    <div className="section-title">
                        {/* <span>Schedule Plan</span> */}
                        <h2>Agenda-at-a-Glance</h2>

                        {/* <LaxDiv text="Events" dataPreset="driftLeft" /> */}

                        {/* <Link to="#" className="btn btn-primary">Register Now!</Link> */}

                        {/* <div className="bar"></div> */}
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab">
                                <ul className="tabs active">
                                    <li
                                        onClick={(e) => this.openTabSection(e, 'tab1')}
                                        className="current"
                                    >
                                        <Link to="#">
                                            Day 1
                                            <span>TUESDAY, 21 May 2024</span>
                                        </Link>
                                    </li>

                                    <li onClick={(e) => this.openTabSection(e, 'tab2')}>
                                        <Link to="#">
                                            Day 2
                                            <span>WEDNESDAY, 22 May 2024</span>
                                        </Link>
                                    </li>

                                    <li onClick={(e) => this.openTabSection(e, 'tab3')}>
                                        <Link to="#">
                                            Day 3
                                            <span>THURSDAY, 23 May 2024</span>
                                        </Link>
                                    </li>
                                    <li onClick={(e) => this.openTabSection(e, 'tab4')}>
                                        <Link to="#">
                                            Day 4
                                            <span>FRIDAY, 24 May 2024</span>
                                        </Link>
                                    </li>

                                    
                                </ul>

                                <div className="tab_content">
                                    <div id="tab1" className="tabs_item">
                                        <ul className="accordion">
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Arrivals & Check in  </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 09:00 onwards   </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Lunch  </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 13:00 - 15:00    </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>EPAC' 2024<br/>
*Invite only event for select partners 
{/* Welcome Reception  */}
  </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 15:00 - 19:00   </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                {/* <div className="author">
                                                                <img 
                                                                    src={require("../../assets/images/author1.jpg")} 
                                                                    data-toggle="tooltip" 
                                                                    data-placement="top" 
                                                                    title="Steven Smith" 
                                                                    alt="Schedule" 
                                                                />
                                                            </div> */}
                                                    <div className="schedule-info">
                                                        <h3>Welcome reception dinner </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Amer Warsi (Director, Channels and Ecosystems, HPE India )</div> */}
                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 19:00 - 21:00  </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                           
                                           
                                        </ul>	
                                    </div>

                                    <div id="tab2" className="tabs_item">
                                    <ul className="accordion">
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Breakfast and 1:1 meetings  </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 07:00 - 08:00    </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            {/* <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Registration for Ignite Partner Success 2024   </h3>

                                                        <ul>
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 08:30 - 09:30   </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li> */}

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Tour & Tech Walks </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Amer Warsi (Director, Channels and Ecosystems, HPE India )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>9:30 - 10:00   </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>HPE Ignite Partner Success Keynote
</h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Som Satsangi (SVP and Managing Director, HPE India  )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>10:00 - 11:00</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Spotlight Session<br/> Hybrid Cloud 
{/* GTM Priorities  */}
</h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Rajesh Dhar (Senior Director, Industry verticals Sales, HPE India )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>11:00 - 12:00</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Spotlight Session<br/> HPC & AI 
{/* GTM Priorities  */}
 </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Sandeep Pendharkar (Senior Director, HPE Services, HPE India )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>12:00 - 13:00 </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Lunch </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Sandeep Pendharkar (Senior Director, HPE Services, HPE India )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>13:00 - 14:30 </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Team Building    </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Ahmed Ibrahim (Director, Global Business Development – Service Providers, Intel)</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>14:30 - 15:30</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>1:1 meetings   </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Ahmed Ibrahim (Director, Global Business Development – Service Providers, Intel)</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>15:30 - 17:30</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Networking dinner  </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Mitul Mehra (Sales Leader Global Partners, RedHat)</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>19:00 - 21:00</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                          
                                           
                                        </ul>	
                                    </div>

                                    <div id="tab3" className="tabs_item">
                                    <ul className="accordion">
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Breakfast and 1:1 meetings  </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 07:00 - 08:00   </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Registration for Ignite Partner Success 2024   </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i> 08:30 - 09:30   </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Tour & Tech Walks  </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Bhawna Agarwal (Senior Director, High Velocity and Digital Sales,  HPE India )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>9:30 - 10:00</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Spotlight Session<br/> Edge
{/* BU priorities */}
</h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Suryanarayan CS (Country Manager, Channels, HPE Aruba Networking)</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>10:00 - 11:00 </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Ecosystem Sponsors Track </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Nilotpal Kumar Dutta (Director and Country Manager, HPE GreenLake Cloud Services)</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>11:00 - 12:20</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Closing Note  </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Rishi Gupta (Director, CSLV, HPE India )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>12:20 - 13:00</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Lunch  </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Sandeep Pendharkar (Senior Director, HPE Services, HPE India )</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>13:00 - 14:30 </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>1:1 meetings   </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Mitul Mehra (Sales Leader Global Partners, RedHat)</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>14:30 - 17:30</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                          
                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Awards and Recognition  </h3>
                                                        {/* <div style={{fontStyle: 'italic',marginTop:-10}}>Speaker: Mitul Mehra (Sales Leader Global Partners, RedHat)</div> */}

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>17:30 - 22:00</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                           
                                        </ul>	
                                    </div>

                                    <div id="tab4" className="tabs_item">
                                        <ul className="accordion">
                                        <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Breakfast  </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>07:00 - 09:00 </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>

                                            <li className="accordion-item">
                                                <div className="accordion-title" to="#">
                                                   
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Departures  </h3>

                                                        <ul>
                                                            {/* <li><i className="icofont-user-suited"></i> By <span>Tom Cruise</span> CEO of EnvyTheme</li> */}
                                                            <li style={{fontSize:18}}><i className="icofont-wall-clock"></i>10:00 onwards</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                               
                                            </li>
                                           
                                          
                                        </ul>	
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-12">
                            <div className="btn-box">
                                <Link to="#" className="btn btn-primary">Download Schedule (PDF)</Link>
                                <Link to="#" className="btn btn-secondary">Connect Via Instagr</link>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* <div className="shape1"><img src={require("../../assets/images/shapes/1.png")} alt="shape1" /></div> */}
                {/* <div className="shape2 rotateme"><img src={require("../../assets/images/shapes/2.png")} alt="shape2" /></div> */}
                {/* <div className="shape3 rotateme"><img src={require("../../assets/images/shapes/3.png")} alt="shape3" /></div> */}
                {/* <div className="shape4"><img src={require("../../assets/images/shapes/4.png")} alt="shape4" /></div> */}
            </section>
        );
    }
}
 
export default EventSchedules;