import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <>
       <section className="about-area ptb-120 bg-image" id="about1">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                        <div
                className="about-content"
                style={{ fontSize: 18, textAlign: "center" }}
              >
                <h3>About Phuket <br/>
</h3>
                {/* <br /> */}
                <p style={{ fontSize: 18 }}>
                Nestled along Thailand's picturesque coastline, Phuket is renowned for its stunning beaches, vibrant culture, and vibrant nightlife. As one of the country's most popular destinations, Phuket offers a perfect blend of relaxation and adventure, with crystal-clear waters, lush landscapes, and a myriad of activities to explore.
                                </p>
                                <br />
                                <h3>About Venue – JW Marriot Phuket Resort & Spa<br/>
</h3>
              
                <p style={{ fontSize: 18 }}>
                Amidst this tropical paradise lies the JW Marriott Phuket Resort & Spa, a luxurious oasis offering unparalleled comfort and indulgence. Set amidst lush gardens and overlooking the azure waters of the Andaman Sea, this resort provides the perfect retreat for travelers seeking a tranquil escape. From lavish accommodations to world-class dining experiences and rejuvenating spa treatments, JW Marriott Phuket Resort & Spa promises an unforgettable stay in one of Thailand's most breathtaking destinations.                                </p>
                {/* <button
                  to="/pricing-1"
                  className="btn btn-primary"
                  onClick={() => {
                    window.open(
                      "https://www.visitdubai.com/en/"
                    );
                  }}
                >
                  MORE ABOUT THE LOCATION  
                </button> */}
              </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src="./w1.jpg" className="about-img1" alt="about" />

                                <img src="./w2.jpg" className="about-img2" alt="about" />

                                {/* <img src={require("../../assets/images/shapes/5.png")} className="shape-img" alt="about" /> */}

                                {/* <LaxButton buttonText="Explore More About" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       
        <footer className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-footer-widget" style={{color:"#fff"}}>
                  <h3>Event Information</h3>
                  <span style={{color:"#fff"}}>
                    <i style={{color:"#fff"}} className="icofont-calendar"></i> 21 May to 24 May,
                    2024
                  </span>

                  <p className="location" style={{color:"#fff"}}>
                    <i className="icofont-google-map"></i> JW Marriot Phuket Resort & Spa
                  </p>

                  <a to="/contact" style={{color:"#fff"}} className="contact-authority">
                    <i className="icofont-email"></i> Contact Us
                    <br />
                    <a style={{color:"#fff"}} href="mailto:harshita.nair@framez.sg">
                      harshita.nair@framez.sg
                    </a>
                    <br />
                    <a style={{color:"#fff"}} href="mailto: igniteaps2024@framez.sg">
                       igniteaps2024@framez.sg
                    </a>
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="single-footer-widget">
                  {/* <h3>Social Connection</h3> */}
                  {/* <p>Don't miss Link thing! Receive daily news You should connect social area for Any Proper Updates Anytime.</p> */}
                  {/* <p>The event is a great opportunity to network and connect with other professionals in your industry. Use #Hashtag to connect with other attendees before, during, and after the event. </p> */}
                  {/* <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/HitachiVantara"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/HitachiVantara"
                        className="twitter"
                        target="_blank"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/hitachi-vantara/"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/c/HitachiVantara"
                        className="youtube"
                        target="_blank"
                      >
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="copyright-area">
                  <div className="logo">
                    <a
                      href="https://www.hitachivantara.com/"
                      target="_blank"
                      to="/"
                    >
                      <img
                        src={require("../../assets/images/hplogo2.png")}
                        width={200}
                        alt="logo"
                      />
                    </a>
                  </div>
                  {/* <ul>
                                    <li><Link to="/blog-1">Blog</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="#">Ticket</Link></li>
                                    <li><Link to="#">Venue</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> */}
                  <p style={{color:"#fff"}}><br/>
                  
                    Copyright <i className="icofont-copyright"></i> 2024 Hewlett Packard Enterprise Development L.P. The information contained herein is subject to change without notice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
