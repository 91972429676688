import React from 'react';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Footer from '../Common/Footer';
 
class Faq extends React.Component {
    submitHandler = (e) => {
        e.preventDefault();
    }
    render(){
        return (
            <React.Fragment>
              

                <section className="faq-area ptb-120"  id="faq">
                <div className="container">
                    <div className="section-title">
                        {/* <span>Listen to the Event Speakers</span> */}
                        <h2>Frequently Asked Questions</h2>
                        <div className="bar"></div>
                        {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
                        {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
                    </div>
                </div>
                    <div className="container">
                        <div className="faq-accordion">
                            <Accordion>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        When and where will HPE Ignite Partner Success 2024 take place?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        HPE Ignite Partner Success 2024, is a 4-day event at HPE Discover 2024 is a 4-day event at JW Marriott Phuket Resort & Spa, Phuket | Thailand taking place from May 21st to 24th, 2024.
                                       <br/> <a onClick={()=>this.props.toggleModal()} style={{color:"red",textDecorationLine:"underline",cursor:"pointer"}}>Register today</a> and receive updates featuring a variety of speakers, announcements, sessions, and more.
                     </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How do I register?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        <a onClick={()=>this.props.toggleModal()} style={{color:"red",textDecorationLine:"underline",cursor:"pointer"}}>Click here </a> to register today. 
                                                                                </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How can I check if I am registered, or what should I do if I haven’t received my confirmation email?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        You should receive a confirmation email from HPE IGNITE PARTNER SUCCESS 2024 after registering. 
Please check your spam folder or contact the Event Support Team at igniteaps2024@framez.sg if you don’t see this email in your inbox.
                                       </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        If I have a valid USA Visa, do I still need to apply for UAE visa? 
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        We would highly encourage you to opt for a UAE E – Visa, as this would enable you to skip the line at the Dubai International Airport. 
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem> */}

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What is the vaccination policy for the event?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Attendees are not required to provide proof of vaccination or proof of negative COVID-19 test result 
Your health and safety are important to us. The team at HPE Ignite continues to monitor guidelines for health and safety measures and will adjust our plans and health protocols, as necessary.  We will follow all applicable laws, adapting our plans accordingly.

                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How do I contact the registration team in case of any requests in my travel plan or itinerary?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Please write to us at igniteaps2024@framez.sg for any additional requirements and we will try our best to accommodate any requests.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How can I contact the registration team? 
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        If you have any registration questions that have not been answered in the FAQs above or have trouble registering, please contact the event Support Team at  igniteaps2024@framez.sg.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
            Do I need a visa to travel to Thailand?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
            Yes, We will facilitate the visa process for attendees. Please ensure to upload the necessary documents for the e-visa application on the registration page prior to your departure. Please ensure that your passport is valid for at least six months beyond your intended stay in Thailand.
        </p>
    </AccordionItemPanel>
</AccordionItem>

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
            What is the attire or dress code for the event?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
            The attire for the event is resort casual for daytime activities and formals for the conference and business sessions. We recommend lightweight, breathable clothing suitable for warm tropical weather, along with comfortable footwear for beachside activities and excursions.
        </p>
    </AccordionItemPanel>
</AccordionItem>

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
            What is the weather like in Phuket during May?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
            May is typically characterized by warm and humid weather in Phuket, with average temperatures ranging from 25°C to 32°C (77°F to 89°F). While occasional rain showers are possible, the weather is generally sunny, making it ideal for outdoor activities and beachside relaxation. It is advisable to pack sunscreen, sunglasses, and a hat to protect against the sun.
        </p>
    </AccordionItemPanel>
</AccordionItem>

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
            Are airport transfers provided for event attendees?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
            Airport transfers on the event dates will be arranged for registered event attendees upon arrival at Phuket International Airport. Our event concierge team will greet you at the airport and assist with transportation to the JW Marriott Phuket Resort & Spa.
        </p>
    </AccordionItemPanel>
</AccordionItem>
                                
                            </Accordion>
                        </div>

                        
                    </div>
                </section>

                {/* <Footer /> */}
            </React.Fragment>
        );
    }
}
 
export default Faq;