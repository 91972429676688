import React from "react";
import lax from "lax.js";
import { Link } from "react-router-dom";
import LaxButton from "../Shared/LaxButton";

class About extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section className="about-area ptb-120 bg-image" id="about">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                {/* <span>Join The Event</span> */}
                <h1>Together we win </h1>
                {/* <h4>
                  
                Seize the opportunity to lead the pack 
                </h4> */}
                <div style={{fontSize:18}}>
               <p style={{fontSize:18}}>At HPE, we believe in partner first. You are central to our strategy, working together to drive growth and accelerate business outcomes. You are invited to HPE Ignite Partner Success 2024 – India’s biggest partner event – taking place between May 21-24 at the JW Marriott Phuket Resort & SPA, Thailand. </p>
                <p style={{fontSize:18}}>HPE is committed to helping partners deliver sustainable innovation around the megatrends of Edge, Hybrid Cloud, and AI, backed by world-class compute technology. Joining the 2024 edition of HPE Ignite Partner Success, you’ll  gain the latest insights, build connections, and learn how to unlock the value of this innovation so we can win together.</p>
           <p style={{fontSize:18}}>During an exclusive partner keynote address, you’ll hear directly from Som Satsangi, SVP & Managing Director, and Amer Warsi, Director, Channels and Alliances, about HPE’s strategy, key opportunities for partners, and how through our innovation we help you be more relevant to your customers to drive transformative business outcomes and deliver superior experiences. You will also be able to engage with multiple HPE business leaders during one-to-one meetings.</p>
             <p style={{fontSize:18}}>We can’t wait to see you in Phuket</p>
            <br/>
             </div>
                {/* <p style={{fontWeight:"bold"}}>Get ready to fast track your organization's growth with HPE India's Annual Partner Summit 2023 </p> */}
                <button
                        // to="/pricing-1"
                        // disabled={this.state.isloading}
                        className="btn newbutton"
                        onClick={() => {
                         
                          this.props.toggleModal()
                          // console.log(firebase.auth().currentUser.uid);
                          // firebase
                          //   .database()
                          //   .ref("pub_user/" + firebase.auth().currentUser.uid)
                          //   .once("value", (snap) => {
                          //     console.log(snap.val());
                          //     if (snap.val().form) {
                          //       this.setState({
                          //         isloading: false,
                          //         modal: true,
                          //         form: true,
                          //       });
                          //     } else {
                          //       console.log("Form not submitted");
                          //       this.setState({
                          //         isloading: false,
                          //         modal: true,
                          //         form: false,
                          //         category: snap.val().category,
                          //       });
                          //     }
                          //   })
                          //   .catch((err) => {
                          //     console.log(err);
                          //   });

                          // firebase.auth().signOut();
                        }}
                      >
                        
                          Register Now
                        
                      </button>
               
                {/* <p>
                With the ever- evolving needs in the business world, it is imperative to discover more growth, more profitability and more opportunities, together to stay ahead of the competition. 
                </p>
                <p>
                The summit offers an opportunity to engage with HPE’s Partner Ecosystem and Industry Experts, gain latest technology updates, all while you experience the City of Gold, Dubai, #1 most popular Destination in the World. 
                </p> */}
              {/*   <p>
                  At Hitachi Vantara, we give our partners the front-row access
                  to emerging technologies like cloud, object storage, Internet
                  of Things (IoT) and much more.
                </p>
                <p>
                  Our offerings help customers adapt to the needs of workloads
                  of the future with a modernized edge-to-core-to-cloud
                  infrastructure that delivers agility, efficiency, and
                  resiliency. Take an integrated, strategic approach to
                  application modernization, data modernization and
                  infrastructure modernization. Work from this digital
                  foundation to innovate, evolve and deliver exceptional
                  experiences for our customers.
                </p>
                <p>
                  As our valued partner, you will have the chance to expand your
                  value by leveraging the Hitachi Vantara offerings.
                </p>
                {/* <p>After almost two years of remote or hybrid  events, we are excited to be back, in person, for our pan India partner event. Hitachi India Partner Summit is a 3-day event designed exclusively for Hitachi Vantara partners. We look forward to having all our partners across the nation under one roof. </p> */}

                {/* <p>The summit offers an opportunity for our partners to engage with the Hitachi Vantara leadership team, hear our latest partner programs and technology updates, network with peers, while you experience the warm and welcoming Bengaluru resort with attractions such as Nandi Hills and others
Come join us to become a part of this unique experience as we gather to celebrate success.
 </p> */}
                {/* <p>Let us gear up with the latest developments and continue to successfully help customers realize the power of data!</p> */}
                {/* <div className="signature">
                                    <img src={require("../../assets/images/signature.png")} alt="signature" />
                                </div> */}

                {/* <Link to="/about-3" className="btn btn-primary">
                                    Read More 
                                    <i className="icofont-double-right"></i>
                                </Link> */}

                {/* <Link to="#" className="btn btn-secondary">Register</Link> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={"./b5.jpg"}
                  className="about-img1"
                  alt="about"
                />

                {/* <img
                  src={"./b1.jpg"}
                  className="about-img2"
                  alt="about"
                /> */}

                {/* <img
                  src={require("../../assets/images/shapes/5.png")}
                  className="shape-img"
                  alt="about"
                /> */}

                {/* <LaxButton buttonText="Explore More About" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
