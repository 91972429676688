// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/database'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  // console.log = () => {};

}
const config = {
  apiKey: "AIzaSyBfeX5v7Sf34Ydm3xQaytBejPKpwdc0WQk",
  authDomain: "igniteaps2024.firebaseapp.com",
  databaseURL: "https://igniteaps2024-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "igniteaps2024",
  storageBucket: "igniteaps2024.appspot.com",
  messagingSenderId: "741487027368",
  appId: "1:741487027368:web:c1ae4130ca0c9e47d6ce9d",
  measurementId: "G-BV9D36B4WW"
};


firebase.initializeApp(config)
firebase.analytics();